import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { UsuariosService } from "../../../services/usuarios.service";

@Component({
  selector: "acessos-usuarios",
  templateUrl: "./acessos-usuarios.component.html",
})
export class AcessosUsuariosComponent implements OnChanges, OnInit {
  @Input("tipo") type: number;
  @Input("idrelacionado") idrelated: number;

  filter = {
    idusu: "",
    nome: "",
    datade: "",
    dataate: "",
    rota: "",
    ip: "",
    navegador: "",
    paginaAtual: 1,
    quantidade: 0,
    tipo: 0,
    idrelacionado: 0,
  };

  arrUsers: any = [];

  contentLoaded = false;

  filtersVisible: boolean = false;

  constructor(private usuariosService: UsuariosService) {}

  ngOnInit() {
    this.filter.tipo = this.type;
    this.filter.idrelacionado = this.idrelated;
  }

  ngOnChanges() {
    this.filter.tipo = this.type;
    this.filter.idrelacionado = this.idrelated;
    this.filterDataGridLocal();
  }

  clearFilters() {
    this.filter.idusu = "";
    this.filter.nome = "";
    this.filter.datade = "";
    this.filter.dataate = "";
    this.filter.rota = "";
    this.filter.ip = "";
    this.filter.navegador = "";
  }

  filterDataGridLocal(): any {
    this.contentLoaded = false;

    if (this.filtersVisible) {
      this.filtersVisible = false;
    }

    this.usuariosService
      .getUsuarioAcessos(this.filter)
      .subscribe((response) => {
        if (response.success) {
          this.arrUsers = response.dados;
          this.filter.quantidade = response.quantidade;
        }

        this.contentLoaded = true;
      });
  }

  pageChange(page: number): any {
    this.filter.paginaAtual = page;
    this.filterDataGridLocal();
  }
}
