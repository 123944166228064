import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { catchError, map, retry, tap } from "rxjs/operators";
import { TIPO_PERFIL_USUARIO } from "../enums/tipo-perfil-usuario.enum";
import Swal from "sweetalert2";
import { UsuarioModel } from "../../login/models";

@Injectable()
export class UserService {
  usuario: UsuarioModel | undefined;
  token: string;

  clientesCentralizadora: any;
  clienteOriginal: any;

  credsCentralizadora: any;
  credOriginal: any;

  tipoUsuario = 1;
  alterarDados = true;

  constructor(private _login: HttpClient) {
    this.usuario = JSON.parse(localStorage.getItem("usuario"));
    this.token = localStorage.getItem("token");

    if (localStorage.getItem("clientesCentralizadora"))
      this.clientesCentralizadora = JSON.parse(
        localStorage.getItem("clientesCentralizadora")
      );

    if (localStorage.getItem("clienteOriginal")) {
      this.clienteOriginal = JSON.parse(
        localStorage.getItem("clienteOriginal")
      );
    }

    if (localStorage.getItem("credsCentralizadora"))
      this.credsCentralizadora = JSON.parse(
        localStorage.getItem("credsCentralizadora")
      );

    if (localStorage.getItem("credOriginal"))
      this.credOriginal = JSON.parse(localStorage.getItem("credOriginal"));

    if (localStorage.getItem("tipoUsuario"))
      this.tipoUsuario = JSON.parse(localStorage.getItem("tipoUsuario"));

    if (localStorage.getItem("alterarDados"))
      this.alterarDados = JSON.parse(localStorage.getItem("alterarDados"));
  }

  login(
    login: { usuario: string; senha: string },
    lembrarUsuario: boolean
  ): Observable<any> {
    const body = JSON.stringify(login);

    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this._login.post("login/autenticacao", body, options).pipe(
      retry(3),
      catchError(this.handleError),
      map((res: any) => {
        if (res.success && res.usuario.nstatus != 2) {
          if (res.usuario.usuarioclientes.length > 0) {
            res.usuario.idcliente = res.usuario.usuarioclientes[0].idcliente;
          } else {
            res.usuario.idcliente = "0";
          }

          switch (res.usuario.idperfil) {
            case TIPO_PERFIL_USUARIO.ADMINISTRADOR_SISTEMA:
            case TIPO_PERFIL_USUARIO.MASTER:
            case TIPO_PERFIL_USUARIO.ADMINISTRADOR_REDE:
              this.tipoUsuario = 1;
              this.alterarDados = true;
              break;
            case TIPO_PERFIL_USUARIO.REPRESENTANTE:
              this.tipoUsuario = 1;
              this.alterarDados = false;
              break;
            case TIPO_PERFIL_USUARIO.CLIENTE:
            case TIPO_PERFIL_USUARIO.GESTOR_FROTA:
              this.tipoUsuario = 2;
              this.alterarDados = true;
              localStorage.setItem(
                "dadosCliente",
                JSON.stringify(res.dadosCliente.cliente)
              );
              break;
            case TIPO_PERFIL_USUARIO.CONSULTAS_CLIENTE:
              this.tipoUsuario = 2;
              this.alterarDados = false;
              localStorage.setItem(
                "dadosCliente",
                JSON.stringify(res.dadosCliente.cliente)
              );
              break;
            case TIPO_PERFIL_USUARIO.VENDAS_CREDENCIADO:
              this.tipoUsuario = 3;
              this.alterarDados = false;
              break;
            case TIPO_PERFIL_USUARIO.CREDENCIADO:
              this.tipoUsuario = 3;
              this.alterarDados = true;
              break;
            case TIPO_PERFIL_USUARIO.PARCEIRO:
              this.tipoUsuario = 4;
              this.alterarDados = true;
              break;
          }

          this.preencherService(res, false);

          let salvar = "";

          if (lembrarUsuario) {
            salvar = res.usuario.login;
          }

          localStorage.setItem("login", salvar);
        }

        return res;
      })
    );
  }

  alterarLogin(token): Observable<boolean> {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "X-Auth-Token-Update": token,
      }),
    };

    return this._login.get("login/validartoken", options).pipe(
      map((res: any) => {
        if (res.success) {
          res.usuario.idcliente = res.idcliente;

          switch (res.usuario.idperfil) {
            case TIPO_PERFIL_USUARIO.ADMINISTRADOR_SISTEMA:
            case TIPO_PERFIL_USUARIO.MASTER:
            case TIPO_PERFIL_USUARIO.ADMINISTRADOR_REDE:
              this.tipoUsuario = 1;
              this.alterarDados = true;
              break;
            case TIPO_PERFIL_USUARIO.REPRESENTANTE:
              this.tipoUsuario = 1;
              this.alterarDados = false;
              break;
            case TIPO_PERFIL_USUARIO.CLIENTE:
            case TIPO_PERFIL_USUARIO.GESTOR_FROTA:
              this.tipoUsuario = 2;
              this.alterarDados = true;
              localStorage.setItem(
                "dadosCliente",
                JSON.stringify(res.dadosCliente.cliente)
              );
              break;
            case TIPO_PERFIL_USUARIO.CONSULTAS_CLIENTE:
              this.tipoUsuario = 2;
              this.alterarDados = false;
              localStorage.setItem(
                "dadosCliente",
                JSON.stringify(res.dadosCliente.cliente)
              );
              break;
            case TIPO_PERFIL_USUARIO.CREDENCIADO:
              this.tipoUsuario = 3;
              this.alterarDados = true;
              break;
            case TIPO_PERFIL_USUARIO.PARCEIRO:
              this.tipoUsuario = 4;
              this.alterarDados = true;
              break;
          }

          this.preencherService(res, true);
        }

        return res.success;
      })
    );
  }

  alterarLoginCred(token): Observable<boolean> {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "X-Auth-Token-Update": token,
      }),
    };

    return this._login.get("login/validartokencred", options).pipe(
      map((res: any) => {
        if (res.success) {
          res.usuario.idcredenciado = res.idcredenciado;

          this.tipoUsuario = 3;
          this.alterarDados = true;

          this.preencherService(res, true);
        }

        return res.success;
      })
    );
  }

  preencherService(res, interno: boolean) {
    if (res.clienteOriginal) {
      this.clienteOriginal = res.clienteOriginal;
    } else {
      this.clienteOriginal = undefined;
    }

    if (res.credOriginal) {
      this.credOriginal = res.credOriginal;
    }
    this.clientesCentralizadora = res.clientesCentralizadora;
    this.usuario = res.usuario;
    this.token = res.token;

    this.credsCentralizadora = res.credsCentralizadora;
    if (res.usuario.trocasenha == 1 || interno) {
      this.setLocalStorage();
    }
  }

  setLocalStorage() {
    if (this.token) localStorage.setItem("token", this.token);
    if (this.usuario)
      localStorage.setItem("usuario", JSON.stringify(this.usuario));
    if (this.clientesCentralizadora)
      localStorage.setItem(
        "clientesCentralizadora",
        JSON.stringify(this.clientesCentralizadora)
      );
    if (this.clienteOriginal) {
      localStorage.setItem(
        "clienteOriginal",
        JSON.stringify(this.clienteOriginal)
      );
    }
    if (this.credsCentralizadora)
      localStorage.setItem(
        "credsCentralizadora",
        JSON.stringify(this.credsCentralizadora)
      );
    if (this.credOriginal)
      localStorage.setItem("credOriginal", JSON.stringify(this.credOriginal));
    if (this.tipoUsuario)
      localStorage.setItem("tipoUsuario", this.tipoUsuario?.toString());
    if (this.alterarDados)
      localStorage.setItem("alterarDados", this.alterarDados?.toString());
  }

  trocarSenhaUsuarioObrigatoria(login): Observable<any> {
    const body = JSON.stringify(login);

    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "X-Auth-Token-Update": this.token,
      }),
    };

    return this._login
      .post("login/trocarsenhausuarioobrigatoria", body, options)
      .pipe(
        retry(3),
        catchError(this.handleError),
        tap((res: any) => {
          if (res.success) {
            localStorage.setItem("token", res.token);
          }
        })
      );
  }

  reenviarSenha(param: any): Observable<any> {
    const body = JSON.stringify(param);
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this._login
      .post("login/reenviarsenha", body, options)
      .pipe(retry(3), catchError(this.handleError));
  }

  verificaHashRecuperacao(param: any): Observable<any> {
    return this._login
      .get("login/verificahash?hash=" + param.hash)
      .pipe(retry(3), catchError(this.handleError));
  }

  trocarSenhaUsuarioHash(param: any): Observable<any> {
    const body = JSON.stringify(param);
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this._login
      .post("login/trocarsenhausuariohash", body, options)
      .pipe(retry(3), catchError(this.handleError));
  }

  temModulo(id): boolean {
    for (let i = 0; i < this.usuario.modulos.length; i++) {
      if (this.usuario.modulos[i].id == id) {
        return true;
      }
    }

    return false;
  }

  protected handleError(error: any): any {
    Swal.fire(
      "Ocorreu um problema!",
      error._body + " - Por favor, entre em contato com o administrador.",
      "error"
    );

    return of();
  }

  bloquearUsuarios(dados: any) {
    return this._login.put("cliente/bloqueargestoremlote", dados);
  }

  setLog(route: string): void {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "X-Auth-Token-Update": localStorage.getItem("token"),
      }),
    };

    this._login
      .post(
        "usuarios/log",
        {
          route,
        },
        options
      )
      .subscribe();
  }
}
