import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class VeiculoService {
  constructor(private http: HttpClient) {}

  addVeiculo(veiculo: any): Observable<any> {
    return this.http.post("veiculo", { veiculo });
  }

  alterarVeiculo(dados: any): Observable<any> {
    return this.http.put("veiculo", dados);
  }

  salvarNovoLimite(salvarLimite: any): Observable<any> {
    return this.http.post("veiculo/alteralimite", salvarLimite);
  }

  vincularCartaoCoringa(solicitar: any): Observable<any> {
    return this.http.post("veiculo/vincularcartaocoringa", { solicitar });
  }

  desvincularCartaoCoringa(dados: any): Observable<any> {
    return this.http.post("veiculo/desvincularcartaocoringaweb", dados);
  }

  solicitacaoNovoCartao(solicitar: any): Observable<any> {
    return this.http.post("veiculo/solicitarnovocartao", solicitar);
  }

  getDetalhesContrato(id: number): Observable<any> {
    return this.http.get("veiculo/veiculocontrato/" + id);
  }

  getDepartamento(filtros: any): Observable<any> {
    return this.http.get("centrocusto/departamento", {
      params: filtros,
    });
  }

  getFamilia(): Observable<any> {
    return this.http.get("veiculo/getfamilia");
  }

  getFamiliaByMarca(idMarca: any): Observable<any> {
    return this.http.get("veiculo/getFamiliaByMarca/" + idMarca);
  }

  getMarca(idFamilia: any): Observable<any> {
    return this.http.get("veiculo/getmarca/" + idFamilia);
  }

  getModelo(idMarca: any): Observable<any> {
    return this.http.get("veiculo/getmodelo/" + idMarca);
  }

  getModelosComp(filtros: any): Observable<any> {
    return this.http.get("veiculo/getmodeloscomp", {
      params: filtros,
    });
  }

  addModelo(dados: any): Observable<any> {
    return this.http.post("veiculo/modelo", dados);
  }

  attModelo(dados: any): Observable<any> {
    return this.http.put("veiculo/modelo", dados);
  }

  getTipoFrota(): Observable<any> {
    return this.http.get("veiculo/gettipofrota");
  }

  getTipoOleo(): Observable<any> {
    return this.http.get("veiculo/gettipooleo");
  }

  reativarVeiculo(dados: any): Observable<any> {
    return this.http.post("veiculo/reativarveiculo", dados);
  }

  verificarPlaca(filtros: any): Observable<any> {
    return this.http.get("veiculo/verificaplaca", {
      params: filtros,
    });
  }

  getpagination(filtros: any): Observable<any> {
    return this.http.get("veiculo/getpagination", {
      params: filtros,
    });
  }

  getView(filtros: any): Observable<any> {
    return this.http.get("veiculo/view", {
      params: filtros,
    });
  }

  pegarMotoristasPorVeiculo(filtros: any): Observable<any> {
    return this.http.get("veiculo/motoristas", {
      params: filtros,
    });
  }

  pegarTanquesCombustivelPorVeiculo(filtros: any): Observable<any> {
    return this.http.get("veiculo/tanques-combustivel", {
      params: filtros,
    });
  }

  pegarLogAjusteOdometroPorVeiculo(filtros: any): Observable<any> {
    return this.http.get("veiculo/log-ajuste-odometro", {
      params: filtros,
    });
  }

  pegarHistoricoManutencaoPorVeiculo(filtros: any): Observable<any> {
    return this.http.get("veiculo/historico-manutencao", {
      params: filtros,
    });
  }

  removerCliente(id: any, idcliente: any): Observable<any> {
    return this.http.post("veiculo/deletar", {
      id,
      idcliente,
    });
  }

  alterarLimiteEmLote(
    veiculos: any,
    limite: any,
    modulo: any,
    servico: any,
    idcliente: any
  ): Observable<any> {
    return this.http.post("veiculo/alterarLimiteEmLote", {
      veiculos,
      limite,
      servico,
      modulo,
      idcliente,
    });
  }

  getTransacoesVeiculos(filtros: any): Observable<any> {
    return this.http.get("veiculo/getTransacoes", {
      params: filtros,
    });
  }

  importar(planilha: any, idcliente: any): Observable<any> {
    return this.http.post("veiculo/importar", {
      planilha,
      idcliente,
    });
  }

  zeraOdometroVeiculo(veiculos: any): Observable<any> {
    return this.http.post("veiculo/zeraOdometro", {
      veiculos,
    });
  }

  getveiculoalterar(filtros: any): Observable<any> {
    return this.http.get("veiculo/getveiculoalterar", {
      params: filtros,
    });
  }

  getLimitesAlterar(filtros: any): Observable<any> {
    return this.http.get("veiculo/getLimitesAlterar", {
      params: filtros,
    });
  }

  getVeiculoTotaisRastreamento(filtros: any): Observable<any> {
    return this.http.get("veiculo/getveiculototais", {
      params: filtros,
    });
  }

  getVeiculosCarga(filtros: any): Observable<any> {
    return this.http.get("carga/veiculos", {
      params: filtros,
    });
  }

  getVeiculosModal(filtros: any): Observable<any> {
    return this.http.get("veiculo/getveiculosmodal", {
      params: filtros,
    });
  }

  getMarcasComp(filtros: any): Observable<any> {
    return this.http.get("veiculo/getmarcascomp", {
      params: filtros,
    });
  }

  addMarca(dados: any): Observable<any> {
    return this.http.post("veiculo/marca", dados);
  }

  attMarca(dados: any): Observable<any> {
    return this.http.put("veiculo/marca", dados);
  }

  getMarcasCombo(): Observable<any> {
    return this.http.get("veiculo/getmarcascombo");
  }

  getReservas(filtros: any): Observable<any> {
    return this.http.get("veiculo/reservas", {
      params: filtros,
    });
  }

  addReserva(dados: any): Observable<any> {
    return this.http.post("veiculo/reservas", dados);
  }

  delReserva(dados: any): Observable<any> {
    return this.http.put("veiculo/reservas", dados);
  }

  getContratosCombo(filtros: any): Observable<any> {
    return this.http.get("veiculo/contratoCombo", {
      params: filtros,
    });
  }

  addContrato(dados: any): Observable<any> {
    return this.http.post("veiculo/contrato", dados);
  }

  getConsultaSuiv(params: any): Observable<any> {
    return this.http.get("veiculo/consultaSuiv", {
      params: params,
    });
  }

  getVeiculoByPlaca(params: { placa: string }): Observable<any> {
    return this.http.get("veiculo/getVeiculoPlaca", {
      params: params,
    });
  }

  getContratos(filtros: any): Observable<any> {
    return this.http.get("veiculo/veiculocontratolocacao", {
      params: filtros,
    });
  }

  putEditarContrato(params: any): Observable<any> {
    return this.http.put("veiculo/contrato", params);
  }

  putEditarContratoVinculado(params: any): Observable<any> {
    return this.http.put("veiculo/veiculocontratolocacao", params);
  }

  getLogAlteracoes(idveiculo: number): Observable<any> {
    return this.http.get("veiculo/metodos/getalteracoes", {
      params: { idveiculo },
    });
  }

  getLogCadastrado(idveiculo: number): Observable<any> {
    return this.http.get("veiculo/metodos/getusuariocadveiculo", {
      params: { idveiculo },
    });
  }
  getLogDeletado(idveiculo: number): Observable<any> {
    return this.http.get("veiculo/metodos/getusuarioexcluiveiculo", {
      params: { idveiculo },
    });
  }

  transferirVeiculo(dados: any): Observable<any> {
    return this.http.post("veiculo/transferir", dados);
  }

  bloquearVeiculos(dados: any): Observable<any> {
    return this.http.put("veiculo/bloquearveiculosemlote", dados);
  }

  apagarVeiculos(dados: any): Observable<any> {
    return this.http.put("veiculo/apagarveiculosemlote", dados);
  }
}
