import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AvisoService {
  constructor(private http: HttpClient) {}

  getAvisos(filtros: any): Observable<any> {
    return this.http.get("aviso", {
      params: filtros,
    });
  }

  getAvisosPedagio(filtros: any): Observable<any> {
    return this.http.get("aviso/pedagio", {
      params: filtros,
    });
  }

  getAvisosCredenciado(filtros: any): Observable<any> {
    return this.http.get("aviso/credenciado", {
      params: filtros,
    });
  }

  getAvisosManutencao(filtros: any): Observable<any> {
    return this.http.get("aviso/manutencao", {
      params: filtros,
    });
  }

  getTiposAvisos(modulo: number) {
    return this.http.get("aviso/tipos", {
      params: {
        modulo,
      },
    });
  }

  getAvisosLog(filtros: any): Observable<any> {
    return this.http.get("aviso/log", {
      params: filtros,
    });
  }

  getAvisosManutencaoLog(filtros: any): Observable<any> {
    return this.http.get("aviso/manutencao/log", {
      params: filtros,
    });
  }

  addAvisos(dados: any, modulo: number): Observable<any> {
    return this.http.post("aviso/insert", { dados, modulo });
  }

  addAvisosCredenciado(dados: any): Observable<any> {
    return this.http.post("aviso/insert/credenciado", dados);
  }

  addAvisosManutencao(dados: any): Observable<any> {
    return this.http.post("aviso/insert/manutencao", dados);
  }

  enviarHtmlPorEmail(dados: any): Observable<any> {
    return this.http.post("aviso/enviarhtmlporemail", {
      parametro: dados,
    });
  }

  enviarSMSPorEmail(dados: any): Observable<any> {
    return this.http.post("aviso/enviarsmsporemail", {
      parametro: dados,
    });
  }

  reenviarAvisosPorEmail(dados: any): Observable<any> {
    return this.http.post("aviso/reenviaravisosporemail", {
      parametro: dados,
    });
  }

  reenviarAvisosManutencaoPorEmail(dados: any): Observable<any> {
    return this.http.post("aviso/manutencao/reenviaravisosporemail", {
      parametro: dados,
    });
  }
}
