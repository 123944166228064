import { Component, ViewChild, ElementRef, OnInit, Input } from "@angular/core";
import { TransacaoService } from "../../../services/transacao.service";
import { UsuariosService } from "../../../services/usuarios.service";
import { EmpresaService } from "../../../services/empresa.service";
import { UserService } from "../../../services/user.service";
import { ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "pesquisar-transacoes",
  templateUrl: "./pesquisar-transacoes.component.html",
  styleUrls: ["./pesquisar-transacoes.component.scss"],
})
export class PesquisarTransacoesComponent implements OnInit {
  filtro = {
    paginaAtual: 1,
    paginaFinal: 1,
    quantidade: 0,
    modulo: 1,
    limite: "20",
    ordenacao: "dcadas",
    sorting: "desc",
    subredes: [],
    estado: "",
    cliente: "",
    codveiculo: "",
    idnf: "",

    campos: [
      {
        campo: "idtipo",
        dado: true,
        valor: "1",
        string: false,
      },
      {
        campo: "idtipo",
        dado: true,
        valor: "2",
        string: false,
      },
      {
        campo: "idtipo",
        dado: true,
        valor: "3",
        string: false,
      },
      {
        campo: "idtipo",
        dado: true,
        valor: "4",
        string: false,
      },
      {
        campo: "tnn.idtrans",
        dado: "",
        string: false,
      },
      {
        campo: "tnn.tr_ncartao",
        dado: "",
        string: true,
      },
      {
        campo: "pf_veiculos.placa",
        dado: "",
        string: true,
      },
      {
        campo: "tnn.dcadas1",
        dado: "",
        string: true,
      },
      {
        campo: "tnn.dcadas2",
        dado: "",
        string: true,
      },
      {
        campo: "mc.cnomemun",
        dado: "",
        string: true,
      },
      {
        campo: "lgc.cuf",
        dado: "",
        string: true,
      },
      {
        campo: "PF_Clientes.codtn",
        dado: "",
        string: true,
      },
      {
        campo: "creed.codtn",
        dado: "",
        string: true,
      },
      {
        campo: "nsituacao",
        dado: false,
        valor: "2",
        string: false,
      }
    ],
  };

  ordenacao = [
    { estado: 2, campo: "idtransunico" },
    { estado: 2, campo: "idtipo" },
    { estado: 1, campo: "dcadas" },
    { estado: 2, campo: "tr_ncartao" },
    { estado: 2, campo: "placa" },
    { estado: 2, campo: "codtn_cliente" },
    { estado: 2, campo: "cliente" },
    { estado: 2, campo: "razao" },
    { estado: 2, campo: "codtn_cred" },
    { estado: 2, campo: "credenciado" },
    { estado: 2, campo: "municipio" },
    { estado: 2, campo: "uf" },
    { estado: 2, campo: "valortrans" },
    { estado: 2, campo: "idTransAGI" },
    { estado: 2, campo: "csituacao" },
  ];

  arrTransacoes = [];

  conteudoCarregado = true;
  visualizarVeiculo = false;
  usuarioCancela = false;

  idVeiculoVisualizar: number;

  tipoUsuario = 1;
  idperfil = 0;
  cancelaTransacao = 0;

  showModalBuscaCliente = false;
  showModalBuscaCredenciado = false;

  @Input("modulo") modulo = 1;

  showModalVeiculo = false;

  filtrosAbrir: boolean = false;

  constructor(
    private transacaoService: TransacaoService,
    private usuarioService: UsuariosService,
    private empresaService: EmpresaService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    this.tipoUsuario = this.userService.tipoUsuario;
    this.idperfil = this.userService.usuario.idperfil;

    if (this.route.snapshot.data["modulo"]) {
      this.modulo = this.route.snapshot.data["modulo"];
    }

    this.getEmpresas();

    let per: any = JSON.parse(localStorage.getItem("pesquisaTransacao"));

    if (!per) {
      localStorage.setItem(
        "pesquisaTransacao",
        JSON.stringify({ filtro: this.filtro })
      );
    } else {
      this.filtro = per.filtro;
    }
  }

  ngOnInit() {
    this.filtro.modulo = this.modulo;
    this.filtrarDataGridLocal();
  }

  changeLimitePesquisa(limitePesquisa: any) {
    this.filtro.limite = limitePesquisa;
    this.filtrarDataGridLocal(true);
  }

  limparFiltros() {
    this.filtro.cliente = "";
    this.filtro.idnf = "";
    this.filtro.codveiculo = "";
    this.filtro.campos = [
      {
        campo: "idtipo",
        dado: true,
        valor: "1",
        string: false,
      },
      {
        campo: "idtipo",
        dado: true,
        valor: "2",
        string: false,
      },
      {
        campo: "idtipo",
        dado: true,
        valor: "3",
        string: false,
      },
      {
        campo: "idtipo",
        dado: true,
        valor: "4",
        string: false,
      },
      {
        campo: "tnn.idtrans",
        dado: "",
        string: false,
      },
      {
        campo: "tnn.tr_ncartao",
        dado: "",
        string: true,
      },
      {
        campo: "pf_veiculos.placa",
        dado: "",
        string: true,
      },
      {
        campo: "tnn.dcadas1",
        dado: "",
        string: true,
      },
      {
        campo: "tnn.dcadas2",
        dado: "",
        string: true,
      },
      {
        campo: "mc.cnomemun",
        dado: "",
        string: true,
      },
      {
        campo: "lgc.cuf",
        dado: "",
        string: true,
      },
      {
        campo: "PF_Clientes.codtn",
        dado: "",
        string: true,
      },
      {
        campo: "creed.codtn",
        dado: "",
        string: true,
      },
      {
        campo: "nsituacao",
        dado: false,
        valor: "2",
        string: false,
      },
      {
        campo: "idnf",
        dado: "",
        string: true,
      },
    ];

    this.getEmpresas();
  }

  getEmpresas(): void {
    this.empresaService.get().subscribe((response) => {
      if (response.success) {
        for (let i = 0; i < response.empresas.length; i++) {
          response.empresas[i].selecionado = true;
        }

        this.filtro.subredes = response.empresas;
      }
    });
  }

  abrirModalVeiculo(id: number): void {
    this.visualizarVeiculo = false;

    setTimeout(() => {
      this.idVeiculoVisualizar = id;
      this.visualizarVeiculo = true;

      this.showModalVeiculo = true;
    }, 1);
  }

  abrirModalBuscaCliente(): void {
    this.showModalBuscaCliente = true;
  }

  abrirModalBuscaCredenciado(): void {
    this.showModalBuscaCredenciado = true;
  }

  setCredCodtn(codtn: any): void {
    this.filtro.campos[12].dado = codtn.toString();
  }

  setClienteCodtn(codtn: any): void {
    this.filtro.campos[11].dado = codtn.toString();
  }

  sortColun(posicao: any): any {
    this.ordenacao[posicao].estado =
      this.ordenacao[posicao].estado == 2
        ? 1
        : this.ordenacao[posicao].estado == 1
        ? 0
        : 1;
    this.filtro.ordenacao = this.ordenacao[posicao].campo;

    for (let x = 0; x < this.ordenacao.length; x++) {
      if (posicao != x) {
        this.ordenacao[x].estado = 2;
      }
    }

    this.filtro.sorting = this.ordenacao[posicao].estado == 0 ? "DESC" : "ASC";
    this.filtrarDataGridLocal(true);
  }

  filtrarDataGridLocal(zeraPagina = false): any {
    this.usuarioService.getAtributos().subscribe((responseUser) => {
      this.usuarioCancela =
        responseUser.usuario?.atributos[0]?.deltransacao &&
        responseUser.usuario.atributos[0].deltransacao != 0;
    });

    if (zeraPagina) {
      this.filtro.paginaAtual = 1;
    }

    if (this.filtrosAbrir) {
      this.filtrosAbrir = false;
    }

    let filtro: any = Object.assign({}, this.filtro);

    filtro.campos = JSON.stringify(filtro.campos);
    filtro.subredes = JSON.stringify(filtro.subredes);

    this.conteudoCarregado = false;

    this.transacaoService.getTransacao(filtro).subscribe((response) => {
      if (response.success) {
        this.arrTransacoes = response.transacoes;
        this.filtro.paginaFinal = response.paginaFinal;
        this.filtro.quantidade = response.quantidade;
      }

      this.conteudoCarregado = true;

      localStorage.setItem(
        "pesquisaTransacao",
        JSON.stringify({ filtro: this.filtro })
      );
    });
  }

  cancelar(id: number): void {
    Swal.fire({
      title: "Cancelar essa transação?",
      text:
        "Você está prestes a autorizar o cancelamento para transação sob número: " +
        id,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, Cancele!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.transacaoService.cancelarTransacao(id);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire("", "Transação Cancelada com Sucesso! ", "success");
          this.filtrarDataGridLocal();
        } else {
          Swal.fire(
            "",
            "Erro ao cancelar a transação! " + result.value.message,
            "error"
          );
        }
      }
    });
  }

  cancelarTransacao(cancelaTransacao): void {
    let filtroCancelar: any = {
      transacaoId: cancelaTransacao,
    };

    Swal.fire({
      title:
        "Rotina de CANCELAMENTO de transação independente da data. Tem certeza que deseja cancelar a transação " +
        cancelaTransacao +
        "?",
      text:
        "Você está prestes a autorizar o cancelamento da transação sob número: " +
        cancelaTransacao +
        '. Lembre-se que ao realizar este cancelamento pode acarretar em conseguências financeiras, pressione "SIM, CANCELE!" se deseja prosseguir:',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, cancele!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.transacaoService.cancelartransacao_xhptwzesa(
          filtroCancelar
        );
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire("", "Transação cancelada com sucesso! ", "success");
          this.filtrarDataGridLocal();
        } else {
          Swal.fire("", "Erro ao cancelar a transação!", "error");
        }
      }
    });
  }

  getClassHeadTable(id: number): string {
    if (this.ordenacao[id].estado == 2) {
      return "sorting";
    } else if (this.ordenacao[id].estado == 0) {
      return "sorting_desc";
    }

    return "sorting_asc";
  }

  paginacaoDireto(filtros): any {
    this.filtro.paginaAtual = filtros;
    this.filtrarDataGridLocal();
  }
}
