import { Component, OnInit } from "@angular/core";
import { VeiculoService } from "../../../veiculo.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "log-alteracoes",
  templateUrl: "./log-alteracoes.component.html",
})
export class LogAlteracoesVeiculoComponent implements OnInit {
  changes: any = [];

  exclusiveFields = ["combustivel", "motorista", "grupo_motorista"];

  vehicleFields = {
    cregional: {
      label: "Centro de Custo",
    },
    csetor: {
      label: "Departamento",
    },
    cveiculo: {
      label: "Veículo",
    },
    lbloqueado: {
      label: "Situação do Cadastro",
      options: {
        0: "Ativo",
        1: "Bloqueado",
      },
    },
    motivobloqueio: {
      label: "Motivo do Bloqueio",
      options: {
        1: "DEVOLUÇÃO",
        2: "SUBSTITUIÇÃO",
        3: "MANUTENÇÃO",
        4: "SEM MOTIVO",
        5: "PENDÊNCIA DOCUMENTAL",
        6: "PENDÊNCIA ENVIO DE KM",
      },
    },
    familia: {
      label: "Família",
    },
    marca: {
      label: "Marca",
    },
    modelo: {
      label: "Modelo",
    },
    cor: {
      label: "Cor",
    },
    tipofrota: {
      label: "Tipo de Frota",
    },
    limprimircartao: {
      label: "Imprimir Cartão",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    numero_contrato: {
      label: "Contrato",
    },
    data_inicio_contrato: {
      label: "Data Início de Vínculo do Contrato",
    },
    data_final_contrato: {
      label: "Data Fim de Vínculo do Contrato",
    },
    valor_da_tarifa: {
      label: "Valor da Tarifa",
    },
    anofabr: {
      label: "Ano de Fabricação",
    },
    ano: {
      label: "Ano do Modelo",
    },
    codveiculo: {
      label: "Código do Veículo",
    },
    ncinlindradas: {
      label: "Cilindradas",
    },
    placa: {
      label: "Placa",
    },
    cuf: {
      label: "Estado",
    },
    municipio: {
      label: "Município",
    },
    chassi: {
      label: "Chassi Número",
    },
    renavam: {
      label: "Renavam",
    },
    mesvenctoipva: {
      label: "Mês de Vencimento do IPVA",
    },
    dataaquisicao: {
      label: "Data de Aquisição",
    },
    datagarantia: {
      label: "Data de Garantia",
    },
    idtipooleo: {
      label: "Tipo de Oleo",
    },
    ncapreservoleo: {
      label: "Capacidade do Reservatório",
    },
    ntoleranreservoleo: {
      label: "Tolerância do Reservatório(%)",
    },
    lrestrireservoleo: {
      label: "Restringir Capacidade do Reservatório",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    lodometro: {
      label: "Odômetro",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    lhorimetro: {
      label: "Horimetro",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    lcontador_naorestringe: {
      label: "Não Controlar (Odômetro ou Horimetro)",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    ncontador: {
      label: "Odomêtro atual (km)",
    },
    ncontmax: {
      label: "Contador máximo (km)",
    },
    ntanques: {
      label: "Nº de Tanques",
    },
    lbicombustivel: {
      label: "Bi-Combustível(flex)",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idtipocombustivel1: {
      label: "Tipo de Combustivel Tanque Principal",
    },
    ncapacidade: {
      label: "Capacidade Tanque Principal (L/M³)",
    },
    tolerancia: {
      label: "Tolerância para Tanque Principal (%)",
    },
    lrest_capacidade: {
      label: "Restringir Capacidade Tanque Principal",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idtipocombustivelreserv: {
      label: "Tipo de Combustivel",
      options: {
        1: "GASOLINA",
        6: "ARLA 32",
      },
    },
    ncapreserv: {
      label: "Capacidade Reservatorio (L)",
    },
    ntoleranreserv: {
      label: "Tolerância para o Reservatorio (%)",
    },
    lrestrireserv: {
      label: "Restringir Capacidade do Reservatorio",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idtipocombustivel2: {
      label: "Tipo de Combustivel Tanque 2",
    },
    nconsumomedio2: {
      label: "Consumo Medio Gasolina Km/L Tanque 2",
    },
    ncapacidade2: {
      label: "Capacidade Tanque 2 (L/M³) ",
    },
    tolerancia2: {
      label: "Tolerância para o Tanque 2 (%)",
    },
    nlimitelitros2: {
      label: "Limite em Litros Tanque 2 (L)",
    },
    lrest_capacidade2: {
      label: "Restringir Capacidade do Tanque 2",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    motorista: {
      label: "Motoristas",
    },
    grupo_motorista: {
      label: "Grupo de Motoristas",
    },
    combustivel: {
      label: "Combustíveis",
    },
    tipo_oleo: {
      label: "Tipo de Óleo",
    },
  };

  dateFields = [
    "data_inicio_contrato",
    "data_final_contrato",
    "dataaquisicao",
    "datagarantia",
  ];

  creationDetails = {
    nome: "",
    dataCadastro: "",
    observacaoCadastro: "",
  };
  deletionDetails = {
    nome: "",
    dataCancelamento: "",
  };

  constructor(
    private veiculoService: VeiculoService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe({
      next: (params) => {
        this.veiculoService
          .getLogAlteracoes(params.id)
          .subscribe((response) => {
            if (response.success) {
              this.changes = response.dados;
            }
          });
        this.getCreationDetails(params.id);
        this.getDeletionDetails(params.id);
      },
    });
  }

  getCreationDetails(vehicleId: number) {
    this.veiculoService.getLogCadastrado(vehicleId).subscribe({
      next: (response) => {
        if (response.success) {
          this.creationDetails = response.usuario[0];
        }
      },
    });
  }

  getDeletionDetails(vehicleId: number) {
    this.veiculoService.getLogDeletado(vehicleId).subscribe({
      next: (response) => {
        if (response.success) {
          if (response.usuario.length > 0) {
            this.deletionDetails = response.usuario[0];
            return;
          }
          this.deletionDetails = {
            nome: "",
            dataCancelamento: "",
          };
        }
      },
    });
  }

  doIsNaN(variable): boolean {
    return (
      isNaN(variable) || variable == "" || !variable?.toString().includes(".")
    );
  }

  isDateField(field: string): boolean {
    return this.dateFields.includes(field);
  }
}
