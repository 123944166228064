import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { SplashScreenService } from "./splash-screen.service";
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { LoggingService } from "./modulos/shared/services/logging.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements AfterViewInit {
  @ViewChild("splashScreen", { static: true }) splashScreen: ElementRef;

  loading = false;
  erro = false;

  constructor(
    private splashScreenService: SplashScreenService,
    private router: Router,
    private loggingService: LoggingService
  ) {
    this.loggingService.startListen();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.erro = false;
        this.loading = true;
      } else if (event instanceof NavigationEnd) {
        this.loading = false;
        this.erro = false;
      } else if (event instanceof NavigationError) {
        this.erro = true;
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.splashScreen) {
      this.splashScreenService.init(this.splashScreen.nativeElement);
    }
  }
}
