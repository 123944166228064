import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class MotoristaService {
  constructor(private http: HttpClient) {}

  getMotoristasCombo(filtros: any): Observable<any> {
    return this.http.get("motorista/motoristacombo", {
      params: filtros,
    });
  }

  getMotoristas(filtros: any): Observable<any> {
    return this.http.get("motorista", {
      params: filtros,
    });
  }

  addMotorista(motorista: any): Observable<any> {
    return this.http.post("motorista", { motorista });
  }

  attMotorista(motorista: any, observacao: any): Observable<any> {
    return this.http.put("motorista", {
      motorista,
      observacao,
    });
  }

  verificarCpf(filtros: any): Observable<any> {
    return this.http.get("motorista/verificarcpf", {
      params: filtros,
    });
  }

  bloquearMotoristas(filtros: any): Observable<any> {
    return this.http.put("motorista/bloquearemlote", filtros);
  }

  importar(planilha: any, idcliente: any): Observable<any> {
    return this.http.post("motorista/importar", {
      planilha,
      idcliente,
    });
  }

  getTransacoesMotorista(filtros: any): Observable<any> {
    return this.http.get("motorista/getTransacoes", {
      params: filtros,
    });
  }

  getLogsAlteracoes(id_cliente: any, id_motorista: any): Observable<any> {
    return this.http.get(
      "motorista/getLogs/" + id_cliente + "/" + id_motorista
    );
  }

  getLogCadastro(idCliente: number, idMotorista: number): Observable<any> {
    return this.http.get(`motorista/${idCliente}/${idMotorista}/log-cadastro`);
  }

  resetPassword(driverId: number): Observable<any> {
    return this.http.put(`motorista/reset-password`, { idmotorista: driverId });
  }
}
