// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  width: 100%;
  max-width: 1700px;
}`, "",{"version":3,"sources":["webpack://./src/app/modulos/veiculos/detalhes/components/fipe/fipe.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;AACF","sourcesContent":[".chart-container {\n  width: 100%;\n  max-width: 1700px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
