import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let isExternal = true;

    const saveToken =
      request.url.split("/")[0] != "login" &&
      request.url.split("/")[0] != "publico";

    if (request.url.substring(0, 4) != "http") {
      isExternal = false;

      request = request.clone({
        url: environment.serverUrl + `${request.url}`,
      });
    }

    if (saveToken && !isExternal) {
      request = request.clone({
        setHeaders: {
          "Content-Type": "application/json",
          "X-Auth-Token-Update": localStorage.getItem("token"),
        },
      });
    }

    return next.handle(request).pipe(
      tap((res) => {
        if (res.type === 0) return;
        if (res instanceof HttpResponse && saveToken && !isExternal) {
          if (res.headers.get("X-Auth-Token-Update")) {
            localStorage.setItem(
              "token",
              res.headers.get("X-Auth-Token-Update")
            );
          }
        }
      }),
      catchError((err) => {
        if (err.status === 401 && !isExternal) {
          let login = localStorage.getItem("login");
          let version = localStorage.getItem("version");
          localStorage.clear();
          localStorage.setItem("version", version);

          if (login) localStorage.setItem("login", login);

          Swal.fire(
            "Sessão Expirada!",
            "Por favor, entre novamente no sistema.",
            "error"
          ).then(() => {
            this.router.navigate(["login"]);
          });
        }

        const error = err.error || err.statusText;
        return throwError(error);
      })
    );
  }
}
