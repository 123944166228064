import { Injectable } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class LoggingService {
  constructor(private router: Router, private userService: UserService) {}

  startListen() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.userService.setLog(event.url);
      }
    });
  }
}
