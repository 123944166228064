import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class CentroCustoService {
  constructor(private http: HttpClient) {}

  getCentroCusto(filtros: any): Observable<any> {
    return this.http.get("centrocusto", {
      params: filtros,
    });
  }

  addCentroCusto(dados: any): Observable<any> {
    return this.http.post("centrocusto", { dados });
  }

  attCentroCusto(dados: any): Observable<any> {
    return this.http.put("centrocusto", { dados });
  }

  getDepartamentos(filtros: any): Observable<any> {
    return this.http.get("centrocusto/departamento/", {
      params: filtros,
    });
  }

  addDepartamento(dados: any): Observable<any> {
    return this.http.post("centrocusto/departamento/", { dados });
  }

  attDepartamento(dados: any): Observable<any> {
    return this.http.put("centrocusto/departamento/", { dados });
  }

  excluir(id: any): Observable<any> {
    return this.http.delete("centrocusto/" + id);
  }

  distribuirCredito(dados: any): Observable<any> {
    return this.http.post("centrocusto/distribuir", dados);
  }

  getGestao(filtros: any): Observable<any> {
    return this.http.get("centrocusto/gestao", {
      params: filtros,
    });
  }

  getLogsAlteracoes(id: any): Observable<any> {
    return this.http.get("centrocusto/getLogs/" + id);
  }

  getRelatorioValorEmpenhado(filtros: any): Observable<any> {
    return this.http.get("consultas/custoEmpenho", {
      params: filtros,
    });
  }

  getRelatorioValorEmpenhadoAbastecimento(filtros: any): Observable<any> {
    return this.http.get("consultas/custoEmpenhoAbastecimento", {
      params: filtros,
    });
  }

  getGestaoMotoristas(filtros: any): Observable<any> {
    return this.http.get("centrocusto/gestaoMotoristas", {
      params: filtros,
    });
  }

  getGestaoVeiculos(filtros: any): Observable<any> {
    return this.http.get("centrocusto/gestaoVeiculos", {
      params: filtros,
    });
  }
}
