import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class UsuariosService {
  constructor(private http: HttpClient) {}

  getUsuario(filtros: any): Observable<any> {
    return this.http.get("usuarios", {
      params: filtros,
    });
  }

  addUsuario(dados: any, tipo: any, id: any): Observable<any> {
    return this.http.post("usuarios", {
      usuario: dados,
      tipo: tipo,
      idrelacionado: id,
    });
  }

  attUsuario(dados: any, tipo: any, id: any): Observable<any> {
    return this.http.put("usuarios", {
      usuario: dados,
      tipo: tipo,
      idrelacionado: id,
    });
  }

  getPerfis(filtros: any): Observable<any> {
    return this.http.get("usuarios/perfis", {
      params: filtros,
    });
  }

  verificarCPF(filtros: any): Observable<any> {
    return this.http.get("usuarios/verificarCPF", {
      params: filtros,
    });
  }

  attSenha(dados: any): Observable<any> {
    return this.http.put("usuarios/alterarsenha", dados);
  }

  getModulos(filtros: any): Observable<any> {
    return this.http.get("usuarios/modulos", {
      params: filtros,
    });
  }

  getModulosUsuario(filtros: any): Observable<any> {
    return this.http.get("usuarios/modulosUsuario", {
      params: filtros,
    });
  }

  getAtributos(): Observable<any> {
    return this.http.get("usuarios/atributos");
  }

  getUsuarioAcessos(filtros: any): Observable<any> {
    return this.http.get("usuarios/logs", {
      params: filtros,
    });
  }
}
