import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class AuthGuard {
  constructor(private router: Router) {}

  canActivate() {
    if (localStorage.getItem("usuario")) {
      return true;
    }

    this.router.navigate(["logout"]);
    return false;
  }
}
