import { CentroCusto } from './../../interfaces/CentroCusto.d';
import { Component, ViewChild, ElementRef, OnInit } from "@angular/core";
import { PublicoService } from "../../services/publico.service";
import { DesempenhoService } from "../../services/desempenho.service";
import { ClienteService } from "../../../clientes/cliente.service";

@Component({
  selector: "desempenho",
  templateUrl: "./desempenho.component.html",
})
export class DesempenhoComponent implements OnInit {
  carregado = false;

  anos = [];
  listaDeCentrosDeCusto = [];

  idcliente = 0;
  idregional = 0;

  filtro = {
    ano: "",
    placa: "",
    centroDeCusto: "",
  };

  filtroDesempenho = {
    ano: "",
    placa: "",
    centroDeCusto: "",
  };

  @ViewChild("anoVar") anoVar: ElementRef;

  constructor(
    private publicoService: PublicoService,
    private desempenhoService: DesempenhoService,
    private clienteService: ClienteService,
  ) {}

  ngOnInit(): void {
    this.idcliente = this.clienteService.getCliente().idcliente,

    this.publicoService.getData().subscribe((response) => {
      this.filtro.ano = response.ano;

      this.anos.push(response.ano);
      this.anos.push((response.ano - 1).toString());

      this.aplicarFiltros();

      this.carregado = true;
    });

    this.desempenhoService.getCentrosDeCustoDoCliente(this.idcliente).subscribe((response) => {
      this.listaDeCentrosDeCusto = response.CentroCusto;
      this.listaDeCentrosDeCusto.forEach((centrocusto) => {
        centrocusto.label = centrocusto.cnome;
      });
    });
  }

  aplicarFiltros() {
    this.filtroDesempenho = JSON.parse(JSON.stringify(this.filtro));
  }
}
