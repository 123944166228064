import { Component, Input, OnInit } from "@angular/core";
import {
  FipeDataVeiculoDetalhe,
  IpvaVeiculoDetalhe,
} from "../../models/veiculo-detalhe.model";
import { Chart } from "angular-highcharts";

interface Series {
  name: string;
  data: number[];
}

@Component({
  selector: "app-fipe",
  templateUrl: "./fipe.component.html",
  styleUrls: [
    "./../../detalhes-veiculo.component.css",
    "./fipe.component.scss",
  ],
})
export class FipeComponent implements OnInit {
  @Input() fipeData: FipeDataVeiculoDetalhe;
  @Input() ipvaData: IpvaVeiculoDetalhe;

  chart: any;

  constructor() {}

  ngOnInit() {
    const arrHistory = this.fipeData.priceHistory.slice(-12);
    const categories = arrHistory.map(
      (item) => `${item.monthUpdate}/${item.yearUpdate}`
    );
    const series: Series = {
      name: "Preço",
      data: arrHistory.map((item) => item.value),
    };
    this.initChart(categories, series);
  }

  initChart(categories: string[], series: Series) {
    this.chart = new Chart({
      chart: {
        type: "area",
        height: 400,
      },
      title: {
        text: "Histórico do Preço",
      },
      credits: {
        enabled: false,
      },
      series: [series],
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          fillOpacity: 0.2,
        },
      },
      yAxis: {
        title: {
          text: "R$",
        },
        min: Math.min(...series.data),
      },
      xAxis: {
        categories: categories,
      },
      exporting: {
        enabled: false,
      },
    } as any);
  }
}
