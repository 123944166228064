import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class LocalidadeService {
  constructor(private http: HttpClient) {}

  getEstados(): Observable<any> {
    return this.http.post("localidade/estados", {});
  }

  getEstadosLocalmente(): Array<{value: string, label: string}> {
    return [
      { value: "", label: "Selecione" },
      { value: "AC", label: "AC" },
      { value: "AL", label: "AL" },
      { value: "AM", label: "AM" },
      { value: "AP", label: "AP" },
      { value: "BA", label: "BA" },
      { value: "CE", label: "CE" },
      { value: "DF", label: "DF" },
      { value: "ES", label: "ES" },
      { value: "GO", label: "GO" },
      { value: "MA", label: "MA" },
      { value: "MG", label: "MG" },
      { value: "MS", label: "MS" },
      { value: "MT", label: "MT" },
      { value: "PA", label: "PA" },
      { value: "PB", label: "PB" },
      { value: "PE", label: "PE" },
      { value: "PI", label: "PI" },
      { value: "PR", label: "PR" },
      { value: "RJ", label: "RJ" },
      { value: "RN", label: "RN" },
      { value: "RO", label: "RO" },
      { value: "RR", label: "RR" },
      { value: "RS", label: "RS" },
      { value: "SC", label: "SC" },
      { value: "SE", label: "SE" },
      { value: "SP", label: "SP" },
      { value: "TO", label: "TO" },
    ];
  }

  getLocalidades(estado: any): Observable<any> {
    return this.http.post("localidade", {
      estado,
    });
  }

  getBairros(localidade: any, municipio = ""): Observable<any> {
    return this.http.post("localidade/bairros", {
      localidade,
      municipio,
    });
  }

  getTiposLog(bairro: any): Observable<any> {
    return this.http.post("localidade/tipolog", {
      bairro,
    });
  }

  getLog(bairro: any, ctipolog: any): Observable<any> {
    return this.http.post("localidade/log", {
      bairro,
      ctipolog,
    });
  }

  getLoge(idlogr: any): Observable<any> {
    return this.http.post("localidade/log", {
      idlogr,
    });
  }

  getCep(log: any): Observable<any> {
    return this.http.post("localidade/cep", {
      log,
    });
  }

  getMunicipioNome(cnomemun: string, cuf: string): Observable<any> {
    return this.http.post("localidade/municipio", {
      cnomemun,
      cuf,
    });
  }

  setNovoEndereco(localidade: any): Observable<any> {
    return this.http.post("localidade/novoendereco", {
      localidade,
    });
  }
}
